import React from "react";
import { useTranslation } from "react-i18next";

function TermsContent() {
  const { i18n } = useTranslation();

  return (
    <>
      <div className="access-content-control">
        <h1 className=" header-control">
          <strong>{i18n.t("termsContent.1")}</strong>{" "}
        </h1>
        <p className="terms-content-control">{i18n.t("termsContent.2")}</p>
        <h1 className=" header-control">
          <strong>{i18n.t("termsContent.3")}</strong>
        </h1>
        <p className="terms-content-control">{i18n.t("termsContent.4")}</p>
        <h1 className=" header-control">
          <strong>{i18n.t("termsContent.5")}</strong>
        </h1>
        <p className="terms-content-control">{i18n.t("termsContent.6")}</p>
        <h1 className=" header-control">
          <strong>{i18n.t("termsContent.7")} </strong>
        </h1>
        <p className="terms-content-control">{i18n.t("termsContent.8")} </p>
        <h1 className=" header-control">
          <strong>{i18n.t("termsContent.9")}</strong>
        </h1>
        <p className="terms-content-control">{i18n.t("termsContent.10")}</p>
        <h1 className=" header-control">
          <strong>{i18n.t("termsContent.11")} </strong>
        </h1>
        <p className="terms-content-control">{i18n.t("termsContent.12")}</p>
        <h1 className=" header-control">
          <strong>{i18n.t("termsContent.13")}</strong>
        </h1>
        <p className="terms-content-control">{i18n.t("termsContent.14")}</p>
        <h1 className=" header-control">
          <strong>{i18n.t("termsContent.15")}</strong>
        </h1>
        <h3 className="header-control">
          <strong>{i18n.t("termsContent.16")}</strong>
        </h3>
        <p className="terms-content-control">{i18n.t("termsContent.17")}</p>
        <h3 className="header-control">
          <strong>{i18n.t("termsContent.18")}</strong>
        </h3>
        <p className="terms-content-control">{i18n.t("termsContent.19")}</p>
        <h3 className="header-control">
          <strong>{i18n.t("termsContent.20")}</strong>
        </h3>
        <p className="terms-content-control">{i18n.t("termsContent.21")}</p>
        <h3 className="header-control">
          <strong>{i18n.t("termsContent.22")}</strong>
        </h3>
        <p className="terms-content-control">{i18n.t("termsContent.23")}</p>
        <h3 className="header-control">
          <strong>{i18n.t("termsContent.24")}</strong>
        </h3>
        <p className="terms-content-control">{i18n.t("termsContent.25")}</p>
        <h3 className="header-control">
          <strong>{i18n.t("termsContent.26")}</strong>
        </h3>
        <p className="terms-content-control">{i18n.t("termsContent.27")}</p>
        <h3 className="header-control">
          <strong>{i18n.t("termsContent.28")}</strong>
        </h3>
        <p className="terms-content-control">{i18n.t("termsContent.29")} </p>
        <h3 className="header-control">
          <strong>{i18n.t("termsContent.30")}</strong>
        </h3>
        <p className="terms-content-control">{i18n.t("termsContent.31")} </p>
        <h3 className="header-control">
          <strong>{i18n.t("termsContent.32")}</strong>
        </h3>
        <p className="terms-content-control">{i18n.t("termsContent.33")}</p>
        <h3 className="header-control">
          <strong>{i18n.t("termsContent.34")}</strong>
        </h3>
        <p className="terms-content-control">{i18n.t("termsContent.35")}</p>
        <h3 className="header-control">
          <strong>{i18n.t("termsContent.36")}</strong>
        </h3>
        <p className="terms-content-control">{i18n.t("termsContent.37")}</p>
        <h3 className="header-control">
          <strong>{i18n.t("termsContent.38")}</strong>
        </h3>
        <p className="terms-content-control">{i18n.t("termsContent.39")}</p>
        <h3 className="header-control">
          <strong>{i18n.t("termsContent.40")}</strong>
        </h3>
        <p className="terms-content-control">{i18n.t("termsContent.41")}</p>
        <h3 className="header-control">
          <strong>{i18n.t("termsContent.42")}</strong>
        </h3>
        <p className="terms-content-control">{i18n.t("termsContent.43")}</p>
        <h3 className="header-control">
          <strong>{i18n.t("termsContent.44")}</strong>
        </h3>
        <p className="terms-content-control">{i18n.t("termsContent.45")}</p>
        <h3 className="header-control">
          <strong>{i18n.t("termsContent.46")}</strong>
        </h3>
        <p className="terms-content-control">{i18n.t("termsContent.47")}</p>
        <h3 className="header-control">
          <strong>{i18n.t("termsContent.48")}</strong>
        </h3>
        <p className="terms-content-control">{i18n.t("termsContent.49")}</p>
        <h3 className="header-control">
          <strong>{i18n.t("termsContent.50")}</strong>
        </h3>
        <p className="terms-content-control">{i18n.t("termsContent.51")}</p>
        <h3 className="header-control">
          <strong>{i18n.t("termsContent.52")}</strong>
        </h3>
        <p className="terms-content-control">{i18n.t("termsContent.53")}</p>
        <h1 className=" header-control">
          <strong>{i18n.t("termsContent.54")}</strong>
        </h1>
        <p className="terms-content-control">{i18n.t("termsContent.55")}</p>
        <h1 className=" header-control">
          <strong>{i18n.t("termsContent.56")}</strong>
        </h1>
        <p className="terms-content-control">{i18n.t("termsContent.57")}</p>
        <h1 className=" header-control">
          <strong>{i18n.t("termsContent.58")}</strong>
        </h1>
        <p className="terms-content-control">{i18n.t("termsContent.59")}</p>
        <h1 className=" header-control">
          <strong>{i18n.t("termsContent.60")}</strong>
        </h1>
        <p className="terms-content-control">{i18n.t("termsContent.61")}</p>
      </div>
    </>
  );
}

export default TermsContent;
