const stories = [
  {
    id_: 1,
    videoTitle1: "Plant a seed – Outside",
    videoTitle2: "Take a walk – Peak",
    videoTitle3: "Connect with someone new – Mermaid",

    videoTitle4: "Consume a little less - Forage",
    videoTitle5: "Be kind to your neighbour - Drift",
    videoTitle6: "Consider the stars - Across the Universe",
    videoTitle7: "Learn one new thing about nature - Den",
  },

  {
    id_: 2,
    videoTitle1: "children stories",
    videoTitle2: "children stories",
    videoTitle3: "children stories",

    videoTitle4: "children stories",
    videoTitle5: "children stories",
    videoTitle6: "children stories",
    videoTitle7: "children stories",
  },
];
export default stories;
